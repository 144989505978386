.text--strong {
  font-weight: 600;
}
body {
  overflow-y: auto !important;
}
header#layoutHeader {
  position: fixed;
  width: -webkit-fill-available;
}
section.ant-layout.ant-layout-has-sider {
  margin-top: 72px;
}
thead.ant-table-thead th {
  white-space: pre-wrap;
}
#root > section > section > aside > div {
  position: fixed;
  width: inherit;
  overflow-y: auto;
}
.ant-tag {
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
}
.ant-layout-sider-children {
  height: calc(100% - 72px);
}
.text--small {
  font-size: 12px;
}
.text--secondary {
  color: #82869e;
}
.w--100 {
  width: 100%;
}
.footer {
  height: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
}
